import React from 'react'

const WhatWeDo = () => {
  return (
    <div id='whatWeDo'>
        <div><h2 className='font-semibold text-4xl text-wt'><center>Our Motto</center></h2></div>
        <div className='m-5'>
            <div><h3 className='text-navColor font-semibold'>About CodeSite:</h3></div>
            <p className='font-medium text-wt'>
            At CodeSite, we are passionate about crafting innovative full-stack software solutions that empower businesses to thrive in the digital age. Our mission is to deliver cutting-edge technology with a keen focus on client success.
            </p>
            <br className='bg-navColor'/>
            <div className='mt-3'><h3 className='text-navColor font-semibold'>Our Expertises:</h3></div>
            <p className='text-wt  font-medium'>
            With a team of dedicated full-stack developers and a commitment to staying at the forefront of technology trends, we offer a wide range of services. From web and mobile application development to cloud-based solutions, we've got you covered.
            </p>
            <div className='mt-3'><h3 className='text-navColor font-semibold'>Why Choose Us:</h3></div>
            <p className='text-wt font-medium mt-3'>Full-Stack Excellence: We have the skills and experience to handle both front-end and back-end development, ensuring your project is in capable hands from start to finish.</p>
            <p className='text-wt font-medium mt-3'>Innovation: We embrace the latest technologies and methodologies to create software that's not just functional, but also future-proof.</p>
            <p className='text-wt font-medium mt-3'>Client-Centric: Your success is our success. We prioritize your goals and satisfaction throughout the project lifecycle.

</p>
            <p className='text-wt font-medium mt-3'>Reliability: We deliver on time and on budget, providing a reliable partner you can count on.</p>
            <div><h3 className='text-navColor font-semibold'>Join Us Now:</h3></div>
        <p className='text-wt font-medium'>We invite you to join us on this exciting journey of software innovation. Whether you're a startup looking to bring your idea to life or an established enterprise seeking digital transformation, CodeSite is here to make your vision a reality.

Contact us today to discuss how we can work together to turn your ideas into dynamic, efficient, and future-ready software solutions.

</p>
        </div>
       

    </div>
  )
}

export default WhatWeDo
