import React from 'react'

const WhoWeAre = () => {
  return (
    <div className='m-5 mt-12' id='#whoWeAre'>
        <div><h2 className='font-semibold text-4xl text-wt'><center>Who we are</center></h2></div>
         <div><h3 className='text-navColor font-semibold'>About us</h3></div>
            <p className='font-medium text-wt'>
            Welcome to CodeSite, where innovation meets excellence in the digital world! Established in 2023, we are a dynamic and forward-thinking software website, dedicated to providing top-notch services to help you navigate the ever-evolving landscape of technology.
            </p>
    </div>
  )
}

export default WhoWeAre